<template>
    <div class="">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="card-table p-2" :class="mode ? 'card__tableday content__titleday' : 'card__tablenight content__titlenight'">
            {{ $t('message.services') }}

            <el-tabs tab-position="left" @tab-click="handleClick">
              <el-tab-pane v-for="(service, index) in categoryServices" :key="'service_'+index">
                <span slot="label" class="font-bold"> {{ service.name }} </span>
                <div class="p-2">
                  <div class="w-100 d-flex mm__space">
                    <p class="font-bold">
                      {{ service.name }}
                    </p>
                    <div class="d-flex">
                      <el-radio-group v-model="filterForm.process">
                        <el-radio-button label="1"> {{ $t('message.active') }} </el-radio-button>
                        <el-radio-button label="2"> {{ $t('message.in_process') }} </el-radio-button>
                        <el-radio-button label="3"> {{ $t('message.finished') }} </el-radio-button>
                      </el-radio-group>
                      <crm-create-and-column-settings
                        :allowCreate="false"
                        :size="'large'"
                        :permission="$options.name"
                        class="mb-2 ml-5"
                        :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                        :columns="columns"
                        @c-change="updateColumn"
                      >
                      </crm-create-and-column-settings>
                    </div>
                  </div>
                 
                  <div class="mt-3">
                    <table 
                      class="table-my-code table-bordered"
                      :class="mode ? 'table__myday' : 'table__mynight'"
                      v-loading="loadingData"
                    >
                      <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

                            <th v-if="columns.deal_id.show">
                                {{ columns.deal_id.title }}
                            </th>

                            <th v-if="columns.process.show">
                                {{ columns.process.title }}
                            </th>

                            <th v-if="columns.user_id.show">
                                {{ columns.user_id.title }}
                            </th>

                            <th style="min-width: 300px;" v-if="columns.comment.show">
                                {{ columns.comment.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                          <th v-if="columns.id.show">
                              <el-input
                                  clearable
                                  size="mini"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.id"
                                  :placeholder="columns.id.title"
                                  class="id_input"
                              ></el-input>
                          </th>

                          <th v-if="columns.name.show">
                              <crm-input
                                  :placeholder="columns.name.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.name"
                              ></crm-input>
                          </th>

                          <th v-if="columns.deal_id.show">
                              <crm-input
                                  :placeholder="columns.deal_id.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.deal_id"
                              ></crm-input>
                          </th>

                          <th v-if="columns.process.show">
                              <crm-input
                                  :placeholder="columns.process.title"
                                  disabled
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.process"
                              ></crm-input>
                          </th>

                          <th v-if="columns.user_id.show">
                              <crm-input
                                  :placeholder="columns.user_id.title"
                                  disabled
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.user_id"
                              ></crm-input>
                          </th>

                          <th v-if="columns.comment.show">
                              <crm-input
                                  :placeholder="columns.comment.title"
                                  disabled
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.comment"
                              ></crm-input>
                          </th>

                          <th v-if="columns.created_at.show">
                              <crm-date-picker
                                  :placeholder="columns.created_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.created_at"
                              ></crm-date-picker>
                          </th>

                          <th v-if="columns.updated_at.show">
                              <crm-date-picker
                                  :placeholder="columns.updated_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.updated_at"
                              ></crm-date-picker>
                          </th>

                          <th
                              class="settinW"
                              v-if="columns.settings.show"
                          ></th>
                      </tr>
                      </thead>

                      <transition-group name="flip-list" tag="tbody">
                        <tr v-for="dealService in list" :key="dealService.id" class="cursor-pointer">

                            <td v-if="columns.id.show">
                                {{ dealService.id }}
                            </td>

                            <td v-if="columns.name.show">
                                {{ dealService.name }}
                            </td>

                            <td v-if="columns.deal_id.show">
                                № {{ dealService.deal_id }}
                            </td>

                            <td v-if="columns.process.show">
                                {{ dealService.process ? dealService.process.name : '' }}
                            </td>

                            <td v-if="columns.user_id.show">
                                {{ dealService.user ? 'ID: ' + dealService.user.id + ', ' + dealService.user.name : '' }}
                            </td>

                            <td v-if="columns.comment.show">
                                <span v-html="dealService.comment"></span>
                            </td>
                          
                            <td v-if="columns.created_at.show">
                                {{ dealService.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ dealService.updated_at }}
                            </td>

                            <td v-if="columns.settings.show" class="settings-td">
                              <!-- v-if="dealService.process.id != 3" -->
                              <el-button @click="changeStatus(dealService)" size="mini" type="primary" icon="el-icon-check"></el-button>
                            </td>
                        </tr>
                      </transition-group>
                    </table>
                    <div class="my___pagination">
                      <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                      ></crm-pagination>
                    </div>
                  </div>
                </div>


              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>

      <el-dialog :title="$t('message.choose_process')" :visible.sync="dialogChangeProcess">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-form-item :label="$t('message.process')" prop="process">
            <el-select class="w-100" v-model="form.process" :placeholder="$t('message.process')">
              <el-option
                v-for="item in processes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('message.comment')">
            <el-input
              type="textarea"
              autosize
              :placeholder="$t('message.comment')"
              :autosize="{ minRows: 2 }"
              v-model="form.comment">
            </el-input>
          </el-form-item>
  
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogChangeProcess = false">{{ $t('message.close') }}</el-button>
          <el-button @click="updateProcess()" type="primary">{{ $t('message.save') }}</el-button>
        </span>
      </el-dialog>
    </div>
</template>
  
<script>
  // @ is an alias to /src
  import list from "@/utils/mixins/list";
  import CrmCreate from "./components/crm-create";
  import CrmUpdate from "./components/crm-update";
  import { mapGetters, mapActions } from "vuex";
  import SelectCurrency from '../../components/selects/select-currency';
  
  export default {
    name: "services",
    mixins: [list],
    components: {
      CrmCreate,
      CrmUpdate,
      SelectCurrency,
    },
  
    data() {
      return {
        priceList: false,
        dialogChangeProcess: false,
        form: {
          process: null,
          comment: ''
        },
        rules: {
          process: [
              { required: true, message: this.$t('message.please_select_input', {input: this.$t('message.process')}), trigger: 'change' },
          ]
        }
      };
    },
  
    computed: {
      ...mapGetters({
        mode: "MODE",
        categoryServices: "services/categoryServices",
        processes: "dealService/processes",
        list: "dealService/list",
        columns: "dealService/columns",
        pagination: "dealService/pagination",
        filter: "dealService/filter",
        sort: "dealService/sort",
      }),
    },

    created() {
      this.updateServiceList({should_process: true});
      this.getProcesses();
    },
    methods: {
      ...mapActions({
        updateCategoryServices: "services/categoryServices",
        updateList: "dealService/index",
        updateServiceProcess: "dealService/updateServiceProcess",
        getProcesses: "dealService/getProcesses",
        setPagination: "dealService/setPagination",
        updateSort: "dealService/updateSort",
        updateFilter: "dealService/updateFilter",
        updateColumn: "dealService/updateColumn",
        updatePagination: "dealService/updatePagination",
        empty: "dealService/empty",
        refreshData: "dealService/refreshData",
      }),
      async updateServiceList(query) {
        await this.updateCategoryServices(query)
          .then(res => {
            if(this.categoryServices.length > 0){
              this.updateServiceFilter(0);
            }
          })
          .catch(err => {
          })
      },
      handleClick(tab) {
        this.updateServiceFilter(tab.index);
      },

      updateServiceFilter(index) {
        let service = this.categoryServices[index];
        if(service && service.id && service.category){
          this.filterForm.service_category_id = service.id;
          this.filterForm.service_id = null;
        }else if(service && service.id) {
          this.filterForm.service_id = service.id;
          this.filterForm.service_category_id = null;
        }
      },

      changeStatus(dealService) {
        if(dealService.process.id == 3){
          this.$confirm(
            this.$t('message.do_you_really_want_to_change_finished_service'),
              this.$t("message.warning"), {
                  confirmButtonText: this.$t("message.yes"),
                  cancelButtonText: this.$t("message.no"),
                  type: "warning"
              }
            )
            .then(() => {
              this.form.id = dealService.id;
              this.dialogChangeProcess = true;
            })
            .catch(() => {
                this.$message({
                    type: "warning",
                    message: this.$t("message.operation_canceled")
                });
            });
        } else {
          this.form.id = dealService.id;
          this.dialogChangeProcess = true;
        }

      },

      updateProcess() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.updateServiceProcess(this.form)
              .then(res => {
                this.fetchData();
                this.$alert(res);
                this.dialogChangeProcess = false
              })
              .catch(err => {
                this.$error_message(err);
              })
          }
        });
      },
  
      
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit("services/EMPTY_LIST");
      next();
    },
  };
</script>
<style>
  .link{
      text-decoration: none;
  }
  .mm__space {
    justify-content: space-between;
  }
</style>
  
  